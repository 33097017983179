import { Component, OnInit, ViewChild,ViewContainerRef, AfterViewInit, ElementRef } from '@angular/core';
import { HttpService } from "@app/services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';
import { Angular2Txt } from 'angular2-txt/Angular2-txt';
import { GlobalsService } from '@services/globals/globals.service'
import { ModalConfirmComponent } from "@html/modals/modal-confirm/modal-confirm.component"
import { DomService } from "@html/modals/modal-confirm/modal-service"
import { IgxDatePickerComponent, DateRangeType } from "igniteui-angular"

import * as xlsx from 'xlsx';
import * as moment from "moment"

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {
	constructor(public gs: GlobalsService,
     	public httpService: HttpService,
     	private alertService: AlertService,
     	public domService: DomService) { }

  	@ViewChild(DataTableDirective) dtElement: DataTableDirective;
  	@ViewChild("alertContainer", { read: ViewContainerRef }) container;
  	@ViewChild("uidt") dpicker: IgxDatePickerComponent;
  	@ViewChild(DataTableDirective)
  	@ViewChild('dtable') distfiletable: ElementRef;
  	@ViewChild("fc") filtersCompany;
  	
  	// DataTables  
  	dtOptions : any = {
    	pagingType: 'full_numbers',
    	iDisplayLength : 100,
    	columnDefs : [ { "targets": [0], "orderable": false }],
    	buttons: [
        	{ extend: 'colvis', text: 'Colunas', columns: [0, 1, 2] },
        	{ extend: 'copy', text: 'Copiar' },
        	{ extend: 'csv' },
        	{ extend: 'print', text: 'Imprimir', title: 'Tech Shop Web' },
        	{ extend: 'excel', title: 'Tech Shop Web' },
        	{ extend: 'pdf', title: 'Tech Shop Web' }
      	],
    	language : {"url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"}
  	};
  	dtTrigger: Subject<any> = new Subject();
 
  	filters : any; 
  	checkin: any;
  	auth : any;
  	users: any = [];
  	rowsEdit: any = [];
  	cardsIds: any = [];
  	listIds: any = [];
  	listGrpIds: any = [];
  	isLoadingTab: boolean = false;
  	isLoadingApprove: boolean = false;
  	actualPeriod: any = {};
  	idCutPeriod: number = null; 
  	actualPositionPeriod: number = 0;
  	periodsCuts: any = [];
  	privileges : any = [];
  	permissions :any;
  	cutSelected: any = null;
  	currentCut: any = null;
  	//date: Date = new Date(Date.now());
  	cutDate: Date;
  	show_cutDate: any = null;
 	approve_date: Date;
  	showApprovalWarning: boolean = false; 
  	pv_admin : boolean = false; 
  	ng_booleans = {
  		is_saving_closure : false
  	};
  	ng_overtime_filter_selector;
  	ng_distfile_table = { columns : [], data : []};  	
  	modals = {
	  	closure : null, 
	  	closure_date : null, 
	  	approval : null, 
	  	sp_approval :null,
	  	distribution : { open : new Subject(), close : new Subject() },
	  	confirm_closure_date : new Subject(), 
	  	confirm_closure : null
  	};
  	// buttons
 	saving_closure : any = {
	    text:"Continuar",
	    disabled: false,
	    onlyIcon: false,
	    isLoading: false,
	    type:"btn btn-primary",
	    icon:"fa fa-spinner fa-spin"	 
  	};
  	search_btn : any = {
  		text:"Buscar",
	    disabled: false,
	    onlyIcon: true,
	    isLoading: false,
	    principal_icon : "fa fa-search",
	    type:"btn btn-primary",
	    icon:"fa fa-spinner fa-spin"	 
  	};


  	ngOnInit() {
    	this.isLoadingTab=true;
    	this.permissions = JSON.parse(localStorage.getItem("permissions"));
    	this.auth = JSON.parse(localStorage.getItem("user"));
    	this.pv_admin = this.gs.getPermission().admin_remover_privilegios_edicion;
    	let struct = JSON.parse(localStorage.getItem("filters_cards"));
    	
    	if(struct){  
    		this.filters = {};  		
    		if(struct.division!=0) {
    			this.filters.id_tipo_grupo = 4;
    			this.filters.id_grupo = struct.division;
    		}else if(struct.department!=0){
    			this.filters.id_tipo_grupo = 3;
    			this.filters.id_grupo = struct.department;
    		}else if(struct.branch != 0){
    			this.filters.id_tipo_grupo = 2;
    			this.filters.id_grupo = struct.branch;
    		}    		
    		this.filtersCompany.reset_filters(struct);    		
    	} 
    	
    	this.loadPeriodCut(null, true);   
  		
    }
    
	
	    
    	

  	search(){   	
  		localStorage.setItem("filters_cards", JSON.stringify(this.filters.filters));
  		this.loadPeriodCut(null, false);
  	}


  	addRowToEdit(e, id) {   
    	if(e.target.checked) this.rowsEdit.push(id);
    	else this.rowsEdit.splice( this.rowsEdit.indexOf(id), 1 );
  	} 

  	open(type) {
    	if(type == 1) {
    		var from = moment(this.actualPeriod.init,"DD-MM-YYYY").format("YYYY-MM-DD");
        	var disableFrom = moment(from).subtract(200, 'years').toDate();
        	var dateMax = moment(from).subtract(1, 'days').toDate();        	
        	this.dpicker.disabledDates = [
          		{type: DateRangeType.Between,dateRange:[disableFrom, dateMax]},
        	];        	
        	this.modals.closure_date.open();
    	} else if(type == 2) {
    		if(!this.cutDate) {
  				this.alertService.info("No es posible hacer el cierre sin configurar la fecha de corte");
  				return 0;
  			}
  			this.modals.closure.open();
    	} else if(type == 3) {

    	}
    }

  	filter(filter){   
  		this.search_btn.disabled = true; 
  		this.search_btn.isLoading = true;		
  		if(!filter.loading && !filter.init) {  			
  			this.filters = filter;   
  			this.search_btn.disabled = false; 
  			this.search_btn.isLoading = false;					
  		}   
 	}


  	modalIsReady(type, modal) {
  		if(type==1) this.modals.closure_date = modal;
  		if(type==2) this.modals.closure = modal;
  		if(type==3) this.modals.approval = modal;
  		if(type==4) this.modals.sp_approval = modal;
  	}  

  	loadPeriodCut(id, firstTime) {  		
  		let uri = (!id) ? '/periodo/tarjeta' : '/periodo/tarjeta/'+id;
  		if(this.filters && this.filters.id_tipo_grupo != 1) uri += '?id_grupo='+this.filters.id_grupo+'&id_tipo_grupo='+this.filters.id_tipo_grupo;  		
    	this.isLoadingTab=true;
    	this.search_btn.disabled = true; 
  		this.search_btn.isLoading = true;	
      	this.httpService.getHttpAuth(uri).subscribe(data => {	      	
	      	this.users = []   
	        this.isLoadingTab=false;
	        this.users=data.data.colaboradores;
	        this.actualPeriod.init=data.data.fechas.inicio;
	        this.actualPeriod.end=data.data.fechas.fin;
	        this.idCutPeriod =  id;  	       
	        this.periodsCuts = data.data.cortes;
	        this.currentCut=data.data.corte;	        
	        if(firstTime) {
	        	this.actualPositionPeriod =  data.data.cortes.length - 1;
        		this.cutSelected = this.periodsCuts[this.actualPositionPeriod];
	        	this.cutDate=(data.data.corte.fecha_aprobacion ? moment(data.data.corte.fecha_aprobacion).toDate() : null);
        		this.show_cutDate = (this.cutDate == null) ? null : moment(this.cutDate).add(1, 'days');
	        	if(!data.data.corte.fecha_aprobacion) this.showApprovalWarning = true;        
	        	this.dtTrigger.next();
		        this.dtElement.dtInstance.then((dtInstance:any) => {
		          //console.log(dtInstance.rows({filter: 'applied'}));
		          let df= dtInstance.rows( { page: 'current' } );
		          //console.log(df);
		        });
	        } else {
	        	this.rerender();
	        }
	        this.search_btn.disabled = false; 
  			this.search_btn.isLoading = false;		      
    	});
  	}


	rerender(): void {  	
	    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
	      // Destroy the table first
	      dtInstance.destroy();
	      // Call the dtTrigger to rerender again
	      this.dtTrigger.next();
	    });
	}	

  	updateIds(value,id,i) {    	
    	if(value.target.checked==true) {
      		this.listIds.push(id);
    	}else{
      		var index = this.listIds.findIndex((item, i) => {
        		return item===id;
      		});
      		this.listIds.splice(index, 1);
    	}
  	}

  	approve() {   
    	if(this.listIds.length==0) { 
      		this.alertService.info("Debes seleccionar al menos a un usuario.");
      		return 0;
    	}
    	this.isLoadingApprove=true;
    	this.httpService.postHttpAuth("/periodo/aprobar", {usuarios :  this.listIds}).subscribe( data => {
      		this.isLoadingApprove = false;
      		this.listIds = [];
      		this.alertService.success("Periodos aprobados correctamente.");      	
      		data.data.modo = 1;
      		this.modals.distribution.open.next(data.data);
      		this.loadPeriodCut(null, false);	      
      	},
      	err => {
        	this.isLoadingApprove=false;
      	});
  	}

  	/* overtime privileges logic */
  	privilege_checks(isAll, evt, id, i){
  		if(isAll){
  			if (!evt.target.checked){
  				this.listGrpIds = [];
  			}else{  				
  				for(let i = 0;i < this.privileges.length; i++){
  					this.listGrpIds.push(this.privileges[i].id_division);  				
  				}
  			}  		
  			return;
  		}


  		if(evt.target.checked){
  			this.listGrpIds.push(id);
  		}else{
  			let idx = this.listGrpIds.findIndex((item, i) => { return item===id; });
  			this.listGrpIds.splice(idx, 1);
  		}  		
  	}

  	privilege_approval(){   
  		this.ng_overtime_filter_selector = 'sucursal';
  		this.isLoadingApprove = true;		
  		this.httpService.getHttpAuth("/periodo/privilegios?id_corte="+this.currentCut.id).subscribe(data => {
  			this.privileges = data.data;
  			this.listGrpIds = [];
      		this.isLoadingApprove = false;	  
      		this.modals.sp_approval.open();    
      	},
      	err => {
        	this.isLoadingApprove=false;
      	});
  	}

  	save_sp_privileges(){
  		this.isLoadingApprove = true;		
  		this.httpService.postHttpAuth("/periodo/privilegios",{
  			"id_corte" : this.currentCut.id, 
  			"id" : this.listGrpIds
  		}).subscribe(data => {  			
  			this.listGrpIds = [];
      		this.isLoadingApprove = false;	
      		this.modals.sp_approval.dismiss(); 	
      		this.alertService.success("Aprobación grupal guardada con éxito.");    		     	
      	},
      	err => {
        	this.isLoadingApprove=false;
      	});
  	}

  	delete_sp_privileges(){  	
  		var danger = false;	
  		var list_edit = [];	
  		var list_create = [];	
  		if(this.listGrpIds.length == 0){
  			this.alertService.info('Seleccione alguna de las agrupaciones para eliminar.');
  			return 0;
  		}

  		for(let x = 0; x < this.listGrpIds.length; x++){
  			let id = this.listGrpIds[x];
  			for(var i=0; i < this.privileges.length; i++){
  				let priv = this.privileges[i];
  				if(priv.id_division== id){
  					if(priv.id_estado == 2 ||  priv.id_estado == 3){
  						list_edit.push(priv.id);  				
  					} else { 
  						list_create.push(priv.id_division);
  					} 
  				}
  			}  			
  		} 		
  		this.isLoadingApprove = true;
  		this.httpService.postHttpAuth("/periodo/borrar-privilegios",{
  			"id_division": list_create,
  			"id" : list_edit
  		}).subscribe(data => {  			
      		this.isLoadingApprove = false;	
      		this.modals.sp_approval.dismiss(); 	
      		this.alertService.success(data.data);    		     	
      	},
      	err => {
        	this.isLoadingApprove=false;
      	});
  	}

  	save_ml_privileges(){   		
  		this.isLoadingApprove = true;		
  		this.httpService.postHttpAuth("/periodo/correo",{}).subscribe(data => {
  			this.isLoadingApprove = false;	  
  			this.alertService.success(data.data);    			 
      	},
      	err => {
        	this.isLoadingApprove=false;
      	});
  	}

  	changePeriod(type){      
    	if(type=='prev') this.actualPositionPeriod--;
     	else this.actualPositionPeriod++;
       	if(this.periodsCuts[this.actualPositionPeriod]){
          	this.cutSelected = this.periodsCuts[this.actualPositionPeriod];  
          	this.loadPeriodCut(this.periodsCuts[this.actualPositionPeriod], false);
      	}else{
        	this.alertService.warning("No existe otro periodo");
      	}
  	}

    save_closure_date(mode, confirm) {
    	if(mode == 1) {
    		this.modals.closure_date.dismiss();
    		this.modals.confirm_closure_date.next(); // this.opens the modal
    	} else {
    		if(confirm) {
    			let fecha_corte = moment(this.approve_date).format("YYYY-MM-DD");
	    		this.httpService.postHttpAuth("/cierre/save",{fecha_corte:fecha_corte}).subscribe(data => {
	                this.alertService.success("Fecha de cierre actualizada.");              
	                this.modals.closure_date.dismiss(); 
	                this.showApprovalWarning=false;
	                this.isLoadingApprove=false;
	                this.cutDate=moment(this.approve_date).toDate();
	                this.show_cutDate = this.cutDate;
	            }, error => {
	                this.isLoadingApprove=false;
	            });
    		}	    		
    	}        
    }
 
  	ng_commitClosure(){
  		this.saving_closure.isLoading = true;
  		this.saving_closure.disabled = true;  	
  		this.httpService.postHttpAuth("/cierre", {}).subscribe(data => {
  		 	this.saving_closure.isLoading = false;
  			this.saving_closure.disabled = false
  		 	this.modals.closure.dismiss();
        	this.alertService.info(data.data);
  		}, error => {
  		 	this.ng_booleans.is_saving_closure = false;
  		});
  	}  
  
  	generateFile(quick){   
	    var $this = this;
	    var success = function(x) {
	    	$this.isLoadingApprove=false;
	    	var filename = x.data.config.filename + " " + moment().format("DD-MM-YY HH:mm")
	    	switch(x.data.config.file){
	    		case 'xlsx': // exportacion a excel
	    			$this.ng_distfile_table.columns = x.data.config.columnas;
	    			$this.ng_distfile_table.data = x.data.data;
	    			setTimeout(function(){
	    				const ws: xlsx.WorkSheet =  xlsx.utils.table_to_sheet($this.distfiletable.nativeElement);
  						const wb: xlsx.WorkBook = xlsx.utils.book_new();
  						xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
  						xlsx.writeFile(wb, filename + '.xlsx'); 
	    			}, 500);	    			
	    			break;

	    		case 'txt':
	    			var options = { 
	        			fieldSeparator: (x.data.config.separator == 'tab' ? "\t" : x.data.config.separator),
	        			quoteStrings:"",
	        			useBom :  x.data.config.bom
	      			};	             
	      			new Angular2Txt(x.data.data, filename ,options);
	    			break;
	    	}      	
	    }

    	var error = function(){
      		$this.isLoadingApprove=false;
    	}
    
   
    	if(quick) {     
      		if(this.listIds.length==0)
      		{ 
        		this.alertService.info("Debes seleccionar al menos a un usuario.");
        		return 0;
      		}   
      		this.isLoadingApprove=true;
      		let listIdsString = this.listIds.toString();      		
      		this.httpService.getHttpAuth('/periodo/csv-rapido?id_corte'+this.idCutPeriod+'&id='+listIdsString).subscribe(success, error);
    	} else {  
      		this.isLoadingApprove=true;     
      		this.httpService.getHttpAuth('/periodo/csv/'+this.cutSelected).subscribe( success, error);
    	}
  	}


 
  
  	chequearVisibles(){
    	this.dtElement.dtInstance.then((dtInstance:DataTables.Api) => {
      		let df= dtInstance.rows( { page: 'current' } );
      		console.log(df);
    	});
  	}
  
  	checkAll(target){    
	    if(this.checkin==false){
	      this.listIds=[];
	      return 0;
	    }
    
	    let users = this.users;
	    this.dtElement.dtInstance.then((dtInstance:DataTables.Api) => {
		    let df= dtInstance.rows( { page: 'current' } );
		    let select = df["0"];
      		for (let i = 0; i < select.length; i++) {
          		if(this.listIds.includes(users[select[i]].id)){
            		var index = this.listIds.indexOf(users[select[i]].id);
            		this.listIds.splice(index, 1);
            		continue;
          		}
          		if(users[select[i]].aprobar!=2) this.listIds.push(users[select[i]].id);      
      		}
    	});
  	}
}
