import { Component, OnInit, ViewChild, Output, Input, EventEmitter, ElementRef, Renderer2 } from '@angular/core';
import { FormatHoursPipe } from "@pipes/format-hours.pipe";
import {HttpService} from '@services/Http/http.service';
import { GlobalsService } from '@services/globals/globals.service'
import { ActivatedRoute } from "@angular/router";
import { AlertService } from 'ngx-alerts';
import { ScheduleService } from '@services/globals/schedule.service';
import { Subject } from 'rxjs';
import * as moment from "moment"
import { Users } from '@interfaces/users'
import { FileServiceService } from "@app/services/files/file-service.service";

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.scss']
})

export class UsersEditComponent implements OnInit {
	@ViewChild('usercalendar') calendar : any;
	@ViewChild('inc') inc_fund;
	@ViewChild('vac') vac_fund;
	@Output() getUser = new EventEmitter();
	@Output() onClose = new EventEmitter();
	@Output() onSaved = new EventEmitter(); // emits back to calendar when the new schedule is saved
	@Input() openModalCalendar = new Subject();

    body : Users;
    grploader : any = JSON.parse(localStorage.getItem('grupo'));

    // groups variables
    branches : any = [];
    departments : any = [];
    divisions : any = [];
    cost_centers : any = [];
    etiqueta_fecha_ingreso = '';
    ng_selected_branch : any;
    ng_selected_department : any;
    ng_selected_division : any;
    ng_selected_cost_center : any;   
    ng_current_user : string = '';
    //ng_id = this.route.snapshot.params.id;
    ng_is_saving : boolean = false;   
    ng_notes : any = [];
    ng_is_new : boolean;
    ng_start_date = new Date();
    ng_users = [];
    modal : any;

    written_inc : string = '';
    written_vac : string = '';

    // booleans
    isLoading: boolean = false;
    isLoadingSave: boolean = false;
    scheduleMode: string = "personal";  
    ng_hide_button_crt_calendar = false;
    reloadCalendar = new Subject();
    saveReady = new Subject();
    resetCalendarFields : Subject<any> = new Subject();    
    photo_perfil: any = null;
    photo_preview: any = null;
    ng_file_status;   
    ng_disabled_user_role = false;
    ng_current_date : any = new Date();
    ng_role : number;
    supervisor_disabled = false;
    ng_roles_list = [{id: 3, name : 'Administrador'},{id : 4, name : 'Supervisor'}, {id : 5, name :'Colaborador'}];
    pv_admin : boolean = false;
    options: any = {
	    text:"Guardar",
	    disabled: false,
	    type:"btn btn-primary",
	    icon:"fa fa-spinner fa-spin",
	    isLoading:false,
	    principal_icon:"fa fa-floppy-o"
  	}; 
   
    constructor(  
    	private formatHoursPipe: FormatHoursPipe,   
        private http : HttpService,
        private route: ActivatedRoute,
        private alertService: AlertService,       
        private scheduleService: ScheduleService,
        private files : FileServiceService,
        private ren : Renderer2,
        public gs: GlobalsService
        ) { 
    	this.ng_role = this.gs.getRole();
    	if(this.ng_role == 4) {
    		if(this.route.snapshot.params.id == this.grploader.id_usuario){
    			this.supervisor_disabled = true;
    		}
    	}       
    }

    format_funds(hours, minutes){    	
    	hours = this.remove_zeros(hours);
		// code for hours	
		if(hours.length == 2) {			
			let hri = parseInt(hours);	
			if(hri < 10 && hri != 0) hours = '0' + hri.toString();
		} else{
			if(hours.length == 0) hours = '00';
			if(hours.length == 1){
				if(hours != '0') hours = '0' + hours;
			}
		}			
		// code for minutes		
		if(minutes.length == 0) minutes  = '00';
		else{
			let mini = parseInt(minutes);									
			if(mini < 10) minutes = '0' + mini.toString(); 	
			if(mini > 60) {
				hours = parseInt(hours) + 1;
				minutes = '00';
			}
		}				

		return hours + ":" + minutes;
    }

    remove_zeros = function(v){
		let res = '';
		let bypass = false;
		for(let i=0; i < v.length;i++){
			if(v[i] == '0' && !bypass) continue; 
			bypass = true;
			res += v[i];
		}
		return res;
    }

    blur(e) {
    	let node = (e.target.id == 'inc') ? this.inc_fund : this.vac_fund;
    	let value = Math.round(parseFloat(e.target.value) * 100) / 100; 
    	console.log(value);
    	/*let split = e.target.value.split(":");    	
    	let value = this.format_funds(split[0], split[1]);
    	let new_value = value.split(":");
    	let min = (parseInt(new_value[0]) * 60) + parseInt(new_value[1]);
    	console.log(min);*/
    	this.ren.setProperty(node.nativeElement, 'value', value);
    }    

    keyup(e) {
    	let list = ['ArrowUp','ArrowDown','ArrowLeft','ArrowRight','Shift','Enter','Backspace','Delete','-','.'];
    	let node = (e.target.id == 'inc') ? this.inc_fund : this.vac_fund;
    	let value = e.target.value;
    	let key = e.key;
    	let separator = value.indexOf(":");
    	
    	if(isNaN(key) && (!list.includes(key))){ 
    		e.stopPropagation();    		
    		var regex = new RegExp(key, 'g');
    		value = value.replace(regex, ''); 
    		
    	} 
    	this.ren.setProperty(node.nativeElement, 'value', value);	  	
    }

    async ngOnInit() {
        // inicializacion de body
       	this.reset();
       	var permission = this.gs.getPermission();
       	this.pv_admin = this.gs.getPermission().admin_remover_privilegios_edicion;
       
       	this.http.getHttpAuth("/grupo/get/"+this.grploader.grupos.empresa.id+"?grupo=sucursal").subscribe(data => {
            this.branches = data.data; 
        }); 
        var id_user = this.route.snapshot.params.id;
        var type_user = this.route.snapshot.params;
       	this.openModalCalendar.subscribe(() => { 
       		if(!this.ng_hide_button_crt_calendar) this.open_modal_calendar(); 
       	});
     
        this.ng_is_new = true;     
        if(id_user == undefined) {
        	this.route.queryParams.subscribe(params => {
	         	if(params.type == "employee"){ 
	         		this.body.id_rol = 5;
	         		this.ng_disabled_user_role = true;
	         	} else {
	         		this.body.id_rol = 3;
	         		if(permission.planilla_time) {
	         		    this.body.id_rol = 4;
	         		    this.ng_roles_list = [{id : 4, name : 'Supervisor'}];
	         		} else {
	         		  	this.ng_roles_list = [
    	         			{id: 3, name : 'Administrador'},
    	         			{id : 7, name : 'IT (Soporte Técnico)'},
    	         			{id : 4, name : 'Supervisor'}
	         		    ];  
	         		}
	         	} 
	        });
	        return;  
        }   
        
        this.http.getHttpAuth("/usuario/"+id_user).subscribe(data => {        	
            let user = data.data.usuario;
            let grupos = data.data.grupos;
            let relaciones = data.data.relaciones;
            console.log(this.grploader.id_usuario);
               
            this.getUser.emit(user);
            this.body.photo_url = (user.perfil) ? user.perfil.url : null; 
            this.body.id = user.id;
            this.body.id_rol = user.id_rol;
            this.body.id_grupo = relaciones.map.id_grupo;
            this.body.id_tipo_grupo = relaciones.map.id_grupo_tipo;
            this.body.nombre = user.nombre;
            this.body.nombre_usuario = user.nombre_usuario;
            this.body.apellido = user.apellido;
            this.body.email = user.email;
            this.body.codigo = user.codigo;
            this.body.codigo_huella = user.codigo_huella;
            this.body.telefono = (relaciones.telefono ? relaciones.telefono.numero : "");
            this.body.id_estado = relaciones.estado.id;  
            this.body.jornada = user.jornada; 
            // set users permissions.
            this.body.permisos.extra = relaciones.permiso.approve_extra;
            this.body.permisos.ver = relaciones.permiso.ver;
            this.body.permisos.editar = relaciones.permiso.editar;
            this.body.permisos.marcar_web = relaciones.permiso.marcar_web;
            this.body.permisos.marcar_app = relaciones.permiso.marcar_app;
            this.body.permisos.mail_marcaciones = relaciones.permiso.mail_marcaciones; 
            this.body.permisos.horario_dinamico = relaciones.permiso.horario_dinamico; 
            this.body.permisos.resumen = relaciones.permiso.mail_h_trabajadas;   
            this.body.permisos.incidencias = relaciones.permiso.mail_incidencias; 
            this.body.permisos.no_marca = relaciones.permiso.no_marca;
            this.body.permisos.ley_pp = relaciones.permiso.ley_pp;  
            this.body.permisos.privileges = relaciones.permiso.aprobar_supervisor; 
            this.body.permisos.admin_remover_privilegios_reportes = relaciones.permiso.admin_remover_privilegios_reportes;
            this.body.permisos.admin_remover_privilegios_edicion = relaciones.permiso.admin_remover_privilegios_edicion;
            this.body.imei = false;
  			this.ng_is_new = false;
  			this.etiqueta_fecha_ingreso = (user.fecha_ingreso ? moment(user.fecha_ingreso, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : null); 
  			this.ng_start_date = (user.fecha_ingreso ? moment(user.fecha_ingreso, "YYYY-MM-DD HH:mm:ss").toDate() : null); 
  			console.log(this.ng_start_date)
   			this.ng_users.push({id : user.id, name: user.nombre  +' '+ user.apellido });  
            if(user.id_rol == 5) {
                this.body.fondo_vacaciones = user.fondo_vacaciones;
                this.body.fondo_incapacidad = user.fondo_incapacidad;
                var round = function(value){
                	if(value == 0) return 0;
                	return Math.round((value/60) * 100) / 100;                 		
                }  
    			let vdays = Math.floor((this.body.fondo_vacaciones / 60) / 8);
    			let idays = Math.floor((this.body.fondo_incapacidad / 60) / 8);
    			let vhours = this.body.fondo_vacaciones; 
    			let ihours =  this.body.fondo_incapacidad;
    			this.written_vac = vdays + " días/" + round(vhours) + " horas";   
    			this.written_inc = idays + " días/" + round(ihours) + " horas";     
                setTimeout(() => {                
                	  			    				        
			        this.ren.setProperty(this.inc_fund.nativeElement,'value', round(this.body.fondo_incapacidad));			       
			        this.ren.setProperty(this.vac_fund.nativeElement,'value', round(this.body.fondo_vacaciones));	    
    			}, 500);         
            }             
          
            // group settings
            this.branches = grupos.sucursal;
            this.departments = grupos.departamento;
            this.divisions = grupos.division;
            this.cost_centers = grupos.centro_costo;
            this.ng_current_user = "Información de Usuario: " + this.body.nombre +" "+ this.body.apellido;

            // selected id for groups
            if(this.body.id_rol != 3 && this.body.id_rol != 6) {
                this.ng_selected_branch = this.getSelectedIDfromGroup(this.branches);
                this.ng_selected_department = this.getSelectedIDfromGroup(this.departments);

                if(this.body.id_tipo_grupo == 3 && this.body.id_rol == 4) {
                    this.body.divisiones = [];
                    this.divisions.forEach(item => {
                        if(item.selected) this.body.divisiones.push(item.id);
                    });                    
                } else {
                    this.ng_selected_division = this.getSelectedIDfromGroup(this.divisions);
                }                

                if(this.body.id_rol == 5) this.ng_selected_cost_center = this.getSelectedIDfromGroup(this.cost_centers);                    
            }
        });
    }

    reset() : void {
    	this.ng_selected_branch = 0;         
    	this.ng_selected_cost_center = 0;         
    	this.ng_selected_division = 0;         
    	this.ng_selected_department = 0;         
    	this.body = {
    		nombre : '',
            apellido : '',
            id_rol : 3,
            id_grupo : null,
            id_tipo_grupo : null,
            divisiones : null,           
            codigo : '',
            codigo_huella : '',
            fondo_vacaciones : 0,
            fondo_incapacidad : 0,           
            fecha_ingreso : null,
            imei : false,
            permisos : {
            	ver : true,
            	editar : true,
            	extra : true,
            	marcar_web : false,
            	marcar_app : false,
            	mail_marcaciones : false,
            	horario_dinamico : false,
            	resumen :false,
            	incidencias : false,
            	ley_pp : false,
            	no_marca : false,
            	privileges :false,
            	admin_remover_privilegios_edicion : false,
            	admin_remover_privilegios_reportes : false
            }        
    	};
    }

    // calendar modal related functions 
    open_modal_calendar() {
    	this.resetCalendarFields.next();
    	this.modal.open();
    }

    calendarLoaded(val) {
    	this.ng_hide_button_crt_calendar = false;
    }

    modalIsReady(modal) : void {
  		this.modal = modal;  		
  	}

  	close_modal_calendar(data) {
  		this.modal.dismiss();
  	}

  	saveSchedule(data) {
  		this.http.postHttpAuth("/horario/users", data).subscribe(x => { 
  			this.ng_hide_button_crt_calendar = true;     	
        	this.alertService.success("Horario agregado correctamente");
        	this.saveReady.next();
        	this.onSaved.emit(x.data);
        	this.reloadCalendar.next(data);
        	this.modal.dismiss();
    	});
  	}

    getByChild(parent, tipo, id) : void { 
        this.body.id_tipo_grupo = parent;
        this.body.id_grupo = id;
        this.body.divisiones = null;       
        if(tipo != '') {
           this.http.getHttpAuth("/grupo/get/"+id+"?grupo="+tipo).subscribe(data => {                           
                if(tipo == 'departamento') { 
                    if(data.data.length == 0){
                        this.departments = [];
                        this.alertService.info("Esta sucursal no tiene divisiones disponibles");
                    } else {                        
                        this.departments = data.data; 
                        this.divisions = []; // limpia los arreglos de division y centro de costo
                        this.cost_centers = []; 
                        this.ng_selected_division = 0;
                    }                    
                } 
                if(tipo == 'division') {
                    if(data.data.length == 0){
                        this.divisions = [];
                        this.alertService.info("Esta división no tiene departamentos disponibles");
                    } else {
                        this.divisions = data.data;  
                        this.cost_centers = [];  
                        this.ng_selected_cost_center = 0;
                    }                    
                } 
                if(tipo == 'centro-costo') {
                    if(data.data.length == 0) {
                        this.cost_centers = [];
                        this.alertService.info("Este departamento no tiene centro de costos disponibles");
                    } else {
                        this.cost_centers = data.data; this.ng_selected_cost_center = 0;
                    }                    
                    
                }
                // usado en un futuro para los filtros de busqueda
                let param = "id_grupo="+id+"&id_tipo_grupo="+parent;                         
            });        
        }        
    }

    save(){  
        this.ng_is_saving = true;          
        if(this.body.id_rol != 3 && this.body.id_rol != 7) { // supervisor and employees validation           
            if(this.body.id_rol == 5) { // employee
                if(this.body.id_grupo == null || this.body.id_tipo_grupo == null) {
                	this.ng_is_saving = false;                    
                    this.alertService.info("Debe seleccionar un centro de costo");
                    return 0;
                }   
                if(this.body.id_tipo_grupo != 5) {  
                	this.ng_is_saving = false;                  
                    this.alertService.info("Debe seleccionar un centro de costo");
                    return 0;
                }  	
                this.body.fondo_incapacidad = Math.round(this.inc_fund.nativeElement.value * 60); 
                this.body.fondo_vacaciones = Math.round(this.vac_fund.nativeElement.value * 60);  
            } else {
                if(this.body.id_grupo == null || this.body.id_tipo_grupo == null) {
                	this.ng_is_saving = false;
                    this.alertService.info("Debe seleccionar al menos una sucursal");
                    return 0;
                }
            }       
        } else {
            this.body.id_tipo_grupo = 1; // is companyCreateShow
            this.body.id_grupo = this.grploader.grupos.empresa.id;
        }
        if(this.ng_start_date) this.body.fecha_ingreso = moment(this.ng_start_date).format('YYYY-MM-DD');
        this.isLoadingSave = true;

        function success($this, data) {
        	$this.ng_is_saving = false;
            $this.isLoadingSave = false;
            $this.onClose.emit(data.data);
            $this.alertService.success('Usuario modificado exitosamente'); 
        }

        function fail($this, error) {
        	$this.ng_is_saving = false;
            $this.isLoadingSave = false;
        }

        if(this.ng_is_new) {
        	this.http.postHttpAuth("/usuario", this.body).subscribe(x => { success(this, x); }, e => { fail(this, e); });
        } else {
        	this.http.putHttpAuth("/usuario/"+this.route.snapshot.params.id, this.body)
        		.subscribe(x => { success(this, x); }, e => { fail(this, e); });
        }       
    }
    
    change_view(code) :void {
        this.scheduleMode = 'personal';             
    }    

    set_calendar_date(date) {
    	this.ng_current_date = date;
    }

    savedManualConcept(data) {
    	console.log(data);
    	this.reloadCalendar.next(data);
    }  

    close_modal() {
    	this.onClose.emit(null);
    }

    getSelectedIDfromGroup(group) : number {
        if(group.length == 0) return 0;
        
        return group.find((item, i) => { if(item.selected) return item.id; }).id;
    }

    
    file_status(evt, status) : void {
      	this.ng_file_status = status ;  
      	if(status == 'reject') {
          	if(evt.reason==2) this.alertService.info("Máximo de tamaño de archivo excedido. (max 5 MB)"); 
          	if(evt.reason==0) this.alertService.info("Formato no permitido, solo se aceptan imagenes");         
      	} 
  	}
      
    async file_upload() {
        if(this.ng_file_status == 'accept') {
          	await this.files.upload(this.photo_perfil[0].file);
          	var myfile = this.files._get_my_file();
          	this.photo_preview= myfile.base64;
          	this.body.new_photo=myfile;
        }             
    }     
      
    changeImage(){
        this.body.photo_url=null;
        this.photo_preview=null;
    }

    

   
}